import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 2-2-2-2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`5-Deadlifts (275/185)`}</p>
    <p>{`7-Burpee Box Jump Overs (24/20″)`}</p>
    <p>{`9-GHD Situps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`January’s Pre Open Competition is completed and the winners are:`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Men’s RX Division: `}</em></strong></p>
    <p><em parentName="p">{`1-Eric Ward`}</em></p>
    <p><em parentName="p">{`2-Michael Hadley`}</em></p>
    <p><em parentName="p">{`3-Travis Lepera`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`Women’s RX Division: `}</em></strong></p>
    <p><em parentName="p">{`1-Kim Lepera`}</em></p>
    <p><em parentName="p">{`2-Ryan Danner`}</em></p>
    <p><em parentName="p">{`3-Becky Oesterritter`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`Scaled Division:`}</em></strong></p>
    <p><em parentName="p">{`1-Sarah Johanboeke`}</em></p>
    <p><em parentName="p">{`2-Nick Johanboeke`}</em></p>
    <p><em parentName="p">{`3-Lisa Brooks`}</em></p>
    <p><em parentName="p">{`Masters (0ver 50) Division: `}</em></p>
    <p><em parentName="p">{`1-Tammy Wicke`}</em></p>
    <p><em parentName="p">{`2-Ed Louden`}</em></p>
    <p><em parentName="p">{`3-Lou Siegwald`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Thanks to everyone who competed! Each division was very competitive
and it was a lot of fun!  Completed standings for each division will be
posted at each gym tomorrow.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar with Dawn from Sports Nutrition 2 Go in Cincinnati. The seminar
will be from 12-2pm. \\$35/person for the seminar. `}{`*`}{`*`}{`*`}{`We still need 8
people in order to have this so please email
Daniel\\@crossfitlouisvilleeast.com if you’d like to attend.`}{`*`}{`*`}{`*`}</strong></p>
    <p><strong parentName="p">{`Subjects covered will include:`}</strong></p>
    <p><strong parentName="p">{`Hydration—How impacts performance, electrolytes, what to drink`}</strong></p>
    <p><strong parentName="p">{`Breakfast—effects afternoon performance by 5% and effects appetite`}</strong></p>
    <p><strong parentName="p">{`Nutrient Timing—what to eat before/after, when`}</strong></p>
    <p><strong parentName="p">{`Sleep and Nutrition on performance---Discuss percentages on outcomes`}</strong></p>
    <p><strong parentName="p">{`Discuss different nutrition philosophies`}</strong></p>
    <p><strong parentName="p">{`Supplements and Labs: what to take and which labs to draw`}</strong></p>
    <p><strong parentName="p">{`Also: She will cover what ever the audience would like! If you would
like to attend and you want to forward questions to her ahead of time
please send them to: Daniel\\@crossfitlouisvilleeast.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Want to learn the exact number of calories your body burns each day?
Metabolic Testing will be offered before and after the seminar as well!
\\$65 for Metabolic Testing. Please email Daniel if you’d like to sign up
for Metabolic testing either before or after the seminar. There is a
sign up sheet at The Ville by the front door and at East on the back
wall. More time slots can be added earlier in the morning if needed.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      